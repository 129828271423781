import React from "react";

const Footer = () => (
    <div className="w-full flex md:justify-center justify-between items-center flex-col p-4 gradient-bg-services">
        <div className="w-full flex sm:flex-row flex-col justify-between items-center my-4">
            <div className="flex flex-1 justify-evenly items-center flex-wrap sm:mt-0 mt-5 w-full">
      	        <p className="text-white text-base text-center mx-2 cursor-pointer"><a href="#home">Home</a></p>
                <p className="text-white text-base text-center mx-2 cursor-pointer"><a href="#exchange">Exchange</a></p>
                <p className="text-white text-base text-center mx-2 cursor-pointer"><a href="#services">Services</a></p>
                <p className="text-white text-base text-center mx-2 cursor-pointer"><a href="#transaction">Transaction</a></p>
            </div>
        </div>
        <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-5 " />
        <div className="sm:w-[90%] w-full flex justify-between items-center mt-3">
            <p className="text-white text-left text-xs">walletconnect-{new Date().getFullYear()}</p>
            <p className="text-white text-right text-xs">Copyright © {new Date().getFullYear()}. All rights reserved.</p>
        </div>
    </div>
);

export default Footer;